import { random } from 'lodash'
import { useGetRewardRedemptionByRewardProgram } from 'hooks/api/Rewards/useGetRewardRedemptionByRewardProgram'
import { DataTable } from 'components/Shared/DataTable'
import { RedemptionColumns } from './RedemptionColumns'

export const RedemptionSection = ({
  rewardProgramId,
  daysDisplayed,
}: {
  rewardProgramId: number
  daysDisplayed: string
}) => {
  const redemptionQuery = useGetRewardRedemptionByRewardProgram({
    rewardProgramId,
    startTransactionDate: new Date(
      new Date().getTime() - Number(daysDisplayed) * 24 * 60 * 60 * 1000
    ).toLocaleString(),
    endTransactionDate: new Date().toLocaleString(),
  })

  if (redemptionQuery.isError) {
    return <p>An error occurred.</p>
  }

  return (
    <DataTable
      sortFieldName="patronId"
      sort="asc"
      columns={RedemptionColumns}
      rows={
        redemptionQuery.data?.map((redemption) => {
          return {
            patronId: redemption.patronId,
            // patronName: redemption.patron?.firstName + ' ' + redemption.patron?.lastName,
            rewardItemId: redemption.rewardsCatalogReward?.rewardId,
            rewardItemName: redemption.rewardsCatalogReward?.reward?.name,
            licensedEstablishmentName:
              redemption.rewardsProgram?.licensedEstablishment?.name,
            pointCost: redemption.rewardsCatalogReward?.amount,
            timeOfredemption: redemption.createdOn,
            rewardProgramName: redemption.rewardsProgram?.name,
          }
        }) ?? []
      }
      getRowId={() => random(0, 1000, true)}
      style={{ maxWidth: 1580 }}
    ></DataTable>
  )
}
